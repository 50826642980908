import { useState } from 'react';
import { capitalizeFirstLetter } from '../../utils/formatters.ts';
import { useDetectClickOutside } from 'react-detect-click-outside';

const SearchTicker = (props) => {
	const API_URL = import.meta.env.PUBLIC_API_URL;
	const [ticker, setTicker] = useState('');
	const [suggestions, setSuggestions] = useState([]);

	const onChangeTicker = (event) => {
		setTicker(event.target.value.toUpperCase());
		const result = fetchAutocompleteSuggestions(event.target.value.toUpperCase());
		result.then((items) => {
			if (items) {
				setSuggestions(items);
			}
		});
	};

	const clearSuggestions = () => {
		setSuggestions([]);
	};

	const ref = useDetectClickOutside({ onTriggered: clearSuggestions });

	const fetchAutocompleteSuggestions = async (inputValue: string) => {
		if (inputValue.length > 1) {
			const response = await fetch(`${API_URL}/stock/autocomplete/${inputValue}`);
			return response.json();
		}
	};

	const redirectToTickerPage = (ticker: string) => {
		// Redirect to another page
		window.location.href = `/${ticker}`;
	};

	const submitTicker = (e: Event) => {
		e.preventDefault();
	};
	return (
		<div className="mx-auto sm:block">
			<form onSubmit={submitTicker}>
				<label htmlFor="icon" className="sr-only">
					Search Ticker
				</label>
				<div className="flex items-center justify-center">
					<div className="relative w-10/12">
						<div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-4">
							<svg
								className="flex-shrink-0 h-4 w-4 dark:text-gray-300 text-gray-500"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<circle cx="11" cy="11" r="8" />
								<path d="m21 21-4.3-4.3" />
							</svg>
						</div>
						<input
							type="text"
							id="icon"
							onChange={onChangeTicker}
							autoComplete="off"
							name="icon"
							className="py-2 px-4 ps-11 pe-20 block dark:bg-gray-800 bg-slate-50 w-full shadow-sm rounded-lg text-sm dark:text-gray-300 dark:border-gray-800 focus:z-10 border focus:border-slate-400 focus:ring-slate-400 placeholder:dark:text-gray-300 border-slate-300 text-gray-500"
							placeholder="Search Ticker"
						/>
						<div
							id="autocomplete-list"
							className={suggestions?.length > 0 ? 'absolute bg-white border rounded-md shadow-md w-full lg:w-1/2' : 'hidden'}
						>
							{suggestions.length > 0 && (
								<ul
									ref={ref}
									className="overflow-hidden absolute w-full border-r border-l border-b bg-white focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
								>
									{suggestions.map((item) => (
										<li
											onClick={() => redirectToTickerPage(item.ticker)}
											key={item.ticker}
											className="cursor-pointer border-gray-300 p-2 px-4 text-gray-700 dark:text-slate-100 hover:bg-slate-100 dark:border-gray-800 dark:bg-gray-900 dark:hover:bg-gray-700"
										>
											<a href={`/${item.ticker}/`}>
												{item.ticker} - {item.company_name} - {capitalizeFirstLetter(item.market)}
											</a>
										</li>
									))}
								</ul>
							)}
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default SearchTicker;
